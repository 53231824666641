module.exports = {
    home: {
        title: 'Bonus, Giveaway, Discount'
    },
    landing: {
        warranty: '24 Months Warranty'
    },
    warranty: {
        title: 'Note: We will not share your personal information with anyone.',
        name: 'Your Name: ',
        nameAlert: 'The name cannot be greater than 32 characters.',
        email: 'Email: ',
        emailAlert: 'The Email cannot be greater than 32 characters.',
        emailAlert2: 'Please enter the correct Email.',
        order: 'Amazon Order Number: ',
        orderAlert: 'Please enter the correct Amazon order number.',
        market: 'Point of Purchase: ',
        interested: 'Are you interested in testing free items?',
        submit: 'Submit'
    },
    finished: {
        title: 'Your warranty application has been approved, enjoy it!'
    },
    alert: {
        90001: 'The order number does not exist, please enter the correct order number.',
        90002: 'Please choose the correct marketplace.',
        90003: 'the order is processing.',
    }
}