<template>
  <v-form ref="form" lazy-validation>
<!--    <v-row class="text-center">
      <v-col>
        <span class="text-h6 white&#45;&#45;text">{{ $t('warranty.title') }}</span>
      </v-col>
    </v-row>-->
    <v-card class="mt-5">
      <v-container fluid>
        <v-row class="mt-3">
          <v-col>
            <span class="font-weight-bold">{{ $t('warranty.email') }}<span class="red--text">*</span></span>
            <v-text-field v-model="email" :rules="rules.email" :disabled="loading" counter="64" clearable tabindex="1"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="font-weight-bold">{{ $t('warranty.name') }}<span class="red--text">*</span></span>
            <v-text-field v-model="name" :rules="rules.name" :disabled="loading" counter="32" clearable tabindex="2"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="font-weight-bold">{{ $t('warranty.order') }}<span class="red--text">*</span></span>
            <v-text-field v-model="order" :rules="rules.order" :label="$t('warranty.orderTip')"
                          :disabled="loading"
                          :counter="orderLength" clearable tabindex="3"/>
          </v-col>
        </v-row>
        <!--<v-row>
          <v-col>
            <span class="font-weight-bold">{{ $t('warranty.market') }}<span class="red&#45;&#45;text">*</span></span>
            <v-select :items="markets" v-model="marketId" tabindex="4"/>
          </v-col>
        </v-row>-->
        <v-row>
          <v-col>
            <v-icon color="red" class="mr-1">fas fa-exclamation-triangle</v-icon>
            <span class="red--text font-weight-bold">{{ $t('warranty.otherContactTip') }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="font-weight-bold">{{ $t('warranty.line') }}</span>
            <v-text-field :label="$t('warranty.lineTip')" v-model="line"
                          :rules="rules.otherContact" clearable
                          :disabled="loading" counter="32" tabindex="5"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="font-weight-bold">{{ $t('warranty.twitter') }}</span>
            <v-text-field :label="$t('warranty.twitterTip')" v-model="twitter"
                          :rules="rules.otherContact"
                          :disabled="loading" counter="32" tabindex="6"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="font-weight-bold">{{ $t('warranty.otherContact') }}</span>
            <v-text-field v-model="otherContact"
                          :rules="rules.otherContact"
                          :disabled="loading" counter="100" tabindex="7"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-icon color="red" class="mr-1">fas fa-exclamation-triangle</v-icon>
            <span class="red--text font-weight-bold">{{ $t('warranty.warningTip') }}</span>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="loading" @click="previous" large color="warning" class="mb-3 mr-3" tabindex="9">{{
            $t('previous')
          }}
        </v-btn>
        <v-btn :loading="loading" @click="next" large color="primary" class="mb-3" tabindex="8">{{
            $t('next')
          }}
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "WarrantyPage",
  computed: {
    orderLength() {
      return this.order && this.order.includes('-') ? 19 : 17
    },
    name: {
      get() {
        return this.$store.state.review.name
      },
      set(val) {
        this.$store.commit('review/NAME', val)
      }
    },
    email: {
      get() {
        return this.$store.state.review.email
      },
      set(val) {
        this.$store.commit('review/EMAIL', val)
      }
    },
    order: {
      get() {
        return this.$store.state.review.order
      },
      set(val) {
        this.$store.commit('review/ORDER', val)
      }
    },
    line: {
      get() {
        return this.$store.state.review.line
      },
      set(val) {
        this.$store.commit('review/LINE', val)
      }
    },
    twitter: {
      get() {
        return this.$store.state.review.twitter
      },
      set(val) {
        this.$store.commit('review/TWITTER', val)
      }
    },
    otherContact: {
      get() {
        return this.$store.state.review.otherContact
      },
      set(val) {
        this.$store.commit('review/OTHER_CONTACT', val)
      }
    },
    marketId: {
      get() {
        return this.$store.state.review.marketId
      },
      set(val) {
        this.$store.commit('review/MARKET', val)
      }
    },
    customer: {
      get() {
        return this.$store.state.review.customer
      },
      set(val) {
        this.$store.commit('review/CUSTOMER', val)
      }
    },
    ...mapState({
      giftId: state => state.review.giftId
    })
  },
  data() {
    return {
      loading: false,
      markets: [
        {
          value: 'US',
          text: 'Amazon US'
        }, {
          value: 'CA',
          text: 'Amazon CA'
        }, {
          value: 'UK',
          text: 'Amazon UK'
        }, {
          value: 'DE',
          text: 'Amazon DE'
        }, {
          value: 'JP',
          text: 'Amazon JP'
        }, {
          value: 'FR',
          text: 'Amazon FR'
        }, {
          value: 'IT',
          text: 'Amazon IT'
        }, {
          value: 'ES',
          text: 'Amazon ES'
        }],
      rules: {
        name: [
          v => !!v && v.length <= 32 || this.$t('warranty.nameAlert')
        ],
        email: [
          v => !!v && v.length <= 64 || this.$t('warranty.emailAlert'),
          v => /^[\\.a-zA-Z0-9_-]+[@＠][a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(v) || this.$t('warranty.emailAlert2')
        ],
        order: [
          v => !!v && v.length <= this.orderLength &&
              /^\d{17}$/.test(v) || /^\d{3}-\d{7}-\d{7}$/.test(v)
              || this.$t('warranty.orderAlert')
        ],
        otherContact: [
          () => !!this.line || !!this.twitter || !!this.otherContact || this.$t('warranty.otherContactAlert')
        ]
      }
    }
  },
  methods: {
    initTestData() {
      if (process.env.NODE_ENV === "development") {
        this.name = "eplang";
        this.email = "ep_lang@163.com";
        this.order = "250-1752240-9395833";
        this.otherContact = 'eplang'
      }
    },
    validateOtherContact() {
      this.$refs.form.validate()
    },
    previous() {
      this.$router.replace('/')
    },
    next() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.getCustomer().then(res => {
          this.loading = false;
          if (res.data) {
            this.customer = res.data;
            this.$router.replace('/survey')
          }
        }, () => {
          this.loading = false
        })
      }
    },
    ...mapActions({
      getCustomer: "review/getCustomer"
    })
  },
  mounted() {
    if (!this.giftId) {
      this.$router.replace("/")
      return
    }
    this.$i18n.locale = this.$route.params.lang.toUpperCase()
    this.marketId = this.$route.params.lang.toUpperCase()
    this.initTestData()
  },
  watch: {
    line: 'validateOtherContact',
    twitter: 'validateOtherContact',
    otherContact: 'validateOtherContact',
  }
}
</script>

<style scoped>

</style>