<template>
  <v-row style="width: 100%" class="mb-1">
    <v-col v-for="item of items" :key="item.id" v-model="trials" :sm="cols">
      <v-container class="item">
        <v-row @click="toggle(item[value])">
          <v-img contain :src="item.imageUrl">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-row>
        <v-row justify="center" style="border-top: 1px solid #aaa">
          <!--<v-radio :label="text" class="ma-3" :value="value"/>-->
          <!--<input
              type="checkbox"
              v-bind:checked="checked"
              v-on:change="$emit('change', $event.target.checked)"
          >-->
          <v-checkbox v-if="multi" ref="checkbox" :label="item[text]" :value="item[value]" v-model="trials" :rules="rules.required"/>
          <v-radio v-else class="ma-3" :label="item[text]" :value="item[value]" v-model="trials"></v-radio>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import {deepCopy} from "@/plugins/util";

export default {
  name: "TrialItem",
  model: {
    prop: "trialsModel",
    event: "update"
  },
  props: {
    items: Array,
    cols: {
      type: Number,
      default: 3
    },
    multi: {
      type: Boolean,
      default: false
    },
    image: String,
    text: String,
    value: [String, Number],
    trialsModel: null,
    selected: String
  },
  computed: {
    trials: {
      get() {
        return this.trialsModel
      },
      set(val) {
        this.$store.commit("review/TRIALS", val)
      }
    }
  },
  data() {
    return {
      checked: null,
      rules: {
        required: [
          () => (this.trials && this.trials.length > 0) || this.$t('trial.trialAlert')
        ]
      }
    }
  },
  methods: {
    toggle(val) {
      if (this.multi) {
        if (this.trials) {
          let newTrials = deepCopy(this.trials)

          let index = newTrials.indexOf(val)
          if (index !== -1) {
            newTrials.splice(index, 1)
          } else {
            newTrials.push(val)
          }
          this.trials = newTrials
        }
      } else {
        this.$emit("update:selected", this.value);
      }
    },
  }
}
</script>

<style scoped>
.item {
  position: relative;
  border-radius: 3px;
  border: 1px solid #aaa;
  /*overflow: revert;*/
}
</style>